import React from "react"
// import { Link } from "gatsby"

// import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
// import MiniLayout from "../../components/minimalLayout"


const Blank = ({data}) => {

  return (
    <>
    </>
  )
}



export default Blank
